import React from "react";
import "../css/Common.css";
import about_us_img_1 from "../images/about_1.png";
import title_icon from "../images/title_shape_1.svg";
import img1 from "../images/about_feature_1_1.svg";
import img2 from "../images/about_feature_1_2.svg";
import img3 from "../images/about_2.png";
import Number_Statistics from "../components/Number_Statistics";
import { FaCheck } from "react-icons/fa";
import ChatButton from "../components/ChatButton";
import { Link } from "react-router-dom";

import moneybip from "../images/moneybip-color-logo.svg"

const About = () => {
  return (
    <>

      <div className="page_wrapper">
        <div className="page_banner_wrapper">
          <div className="page_banner_wrapper_overlay">
            <h2>About Us</h2>
            <div style={{ marginTop: "10px" }}>
              <Link 
                to="/" 
                style={{ 
                  color: "#26b9db", 
                  fontWeight: "600", 
                  fontSize: "14px", 
                  textDecoration: "none" 
                }}
              >
                Home
              </Link>
              <span style={{ color: "white", fontSize: "16px", margin: "0 10px" }}>→</span>
              <span style={{ color: "white", fontWeight: "600", fontSize: "16px" }}>
                About Us
              </span>
            </div>
          </div>
        </div>

        <div className="company_info_section">
          <div className="company_info_wrapper">
            <div className="title_section">
              <h2 className="full-width text-center">Surya Loan</h2>
            </div>
            <p className="text-center  company_info_content">
            Welcome to Surya Loan ,a reliable trusted financial partner powered by Raghavi Finance Limited. Our mission is to empower people to get the money they need to achieve their goals. Surya Loan is a FinTech company that was founded with the objective to consolidate a significant number of salaried professionals who had been turned down for credit for the payment of bills ,salary overdue and many more. It is a brand unit of RAGHAVI FINANCE LIMITED, an NBFC listed with the RBI in 2003, and has a registered office in Delhi.
            </p>
            {/* <div className="button_container">
               <button >
                   Meet Our Leaders
               </button>
               <button>
                Join Our Team
               </button>
            </div> */}
          </div>
        </div>
        <div className="about_us_section_1" data-aos="fade-down">
          <div className="about_us_section_1_left">
            <img src={about_us_img_1} alt="" />
          </div>
          <div className="about_us_section_1_right">
            <div className="title_section">
              <p className="sub_title flex flex-center">
                <img src={title_icon} alt="" className="mr10" />
                <span>About us</span>
              </p>
              <h2 className="mt20">
                Discover SuryaLoan: Your Partner in{" "}
                <span>Financial Growth</span>
              </h2>
            </div>
            <p className="mt30">
            At Surya Loan, we have an experienced loan provider team who are committed to providing tailored based service and innovative lending solutions to meet the various needs of each of our clients. We gladly look forward to the opportunity to help you.
            </p>

            <div className="about_features_section">
              <div className="about_features_item flex flex-center">
                <div>
                  <img src={img1} alt="" />
                </div>
                <div className="ml10">
                  <h3>Certified Company</h3>
                  <p style={{ minWidth: "fit-content" }}>
                    All products certified by RBI
                  </p>
                </div>
              </div>
              <div className="about_features_item flex flex-center">
                <div>
                  <img src={img2} alt="" />
                </div>
                <div className="ml10" style={{ minWidth: "fit-content" }}>
                  <h3>Experienced Team</h3>
                  <p>Certified Professionals</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Number_Statistics />
        <div className="about_us_section_1 pt90">
          <div className="about_us_section_1_right">
            <div className="title_section">
              <p className="sub_title flex flex-center">
                <img src={title_icon} alt="" className="mr10" />
                <span>Why Choose Us</span>
              </p>
              <h2 className="mt20">
                We Deal With the Aspects of
                <br />
                <span>Financial Solutions</span>
              </h2>
            </div>
            <p className="mt30">
            We provide you instant loan approval facility in order to get the money whenever you need.Surya Loan is a well-known platform in India that offers loans to people who fail to meet their credit requirements and did not qualify for loans from other conception lending organizations, or those who are uncomfortable with collateral.
            </p>

            <div className="about_features_section_2">
              <div className="flex flex-center space-between mt20 full-width">
                <div className="about_features_item flex flex-center">
                  <FaCheck className="check_icon mr10" />
                  <h3>Emergency Loans</h3>
                </div>
                <div className="about_features_item flex flex-center">
                  <FaCheck className="check_icon mr10" />
                  <h3>High Quality Security</h3>
                </div>
              </div>
              <div className="flex flex-center space-between mt20 full-width">
                <div className="about_features_item flex flex-center">
                  <FaCheck className="check_icon mr10" />
                  <h3>Online Loans</h3>
                </div>
                <div className="about_features_item flex flex-center">
                  <FaCheck className="check_icon mr10" />
                  <h3>Instant Personal Loans</h3>
                </div>
              </div>
              <div className="flex flex-center space-between mt20 full-width">
                <div className="about_features_item flex flex-center">
                  <FaCheck className="check_icon mr10" />
                  <h3>24/7 Support</h3>
                </div>
                <div className="about_features_item flex flex-center">
                  <FaCheck className="check_icon mr10" />
                  <h3>No Colletral Needed</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="about_us_section_1_2_left">
            <div className="img1">
              <img src={img3} alt="" />
            </div>
          </div>
        </div>

        {/* <div className="about_us_section_1">
          <div className="about_us_section_1_right">
            <div className="title_section">
              <p className="sub_title flex flex-center">
                <img src={title_icon} alt="" className="mr10" />
                <span>Why Choose Us</span>
              </p>
              <h2 className="mt20">
                Our
                <span> Partners</span>
              </h2>
              <div className="partner_image">
                <Link to="https://moneybip.com/" target="_blank">
              <img src={moneybip} alt="" className="mr10"  style={{width:"65%",padding:"30px",marginBottom:"45px"}}/>
              </Link>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <ChatButton />
    </>
  );
};

export default About;
